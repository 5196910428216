import * as singleSpa from "single-spa";
import "./assets/css/style.css";

const applications = [
  {
    name: "raizen-power-ops-mfe-backoffice-home/home",
    //@ts-ignore
    app: () => System.import("raizen-power-ops-mfe-backoffice-home/home"),
    activeWhen: ["/home", (location) => location.pathname.length == 1],
  },
  {
    name: "raizen-power-ops-mfe-atena/atena",
    app: () => System.import("raizen-power-ops-mfe-atena/atena"),
    activeWhen: [
      "/atena",
      (location) => location.pathname.startsWith("/atena"),
    ],
  },
  {
    name: "raizen-power-brain-mfe-hermes/hermes",
    app: () => System.import("raizen-power-brain-mfe-hermes/hermes"),
    activeWhen: [
      "/hermes",
      (location) => location.pathname.startsWith("/hermes"),
    ],
  },
  {
    name: "raizen-power-ops-mfe-tariffs/tarifas",
    app: () => System.import("raizen-power-ops-mfe-tariffs/tarifas"),
    activeWhen: [
      "/tarifas",
      (location) => location.pathname.startsWith("/tarifas"),
    ],
  },
  {
    name: "raizen-power-ops-mfe-power-plants/atlas/usinas",
    app: () => System.import("raizen-power-ops-mfe-power-plants/atlas/usinas"),
    activeWhen: [
      "/atlas/usinas",
      (location) => location.pathname.startsWith("/atlas/usinas"),
    ],
  },
  {
    name: "raizen-power-ops-mfe-coupons/coupon",
    app: () => System.import("raizen-power-ops-mfe-coupons/coupon"),
    activeWhen: [
      "/coupon",
      (location) => location.pathname.startsWith("/coupon"),
    ],
  },
  {
    name: "raizen-power-ops-growth-hacking-mfe-portal/growth-hacking",
    app: () =>
      System.import(
        "raizen-power-ops-growth-hacking-mfe-portal/growth-hacking"
      ),
    activeWhen: [
      "/growth-hacking",
      (location) => location.pathname.startsWith("/growth-hacking"),
    ],
  },
  {
    name: "raizen-power-ops-mfe-distributors/atlas/distribuidoras",
    app: () =>
      System.import("raizen-power-ops-mfe-distributors/atlas/distribuidoras"),
    activeWhen: [
      "/atlas/distribuidoras",
      (location) => location.pathname.startsWith("/atlas/distribuidoras"),
    ],
  },
  {
    name: "raizen-power-brain-gpt-gateway-mfe-portal/ai-agents",
    app: () =>
      System.import("raizen-power-brain-gpt-gateway-mfe-portal/ai-agents"),
    activeWhen: [
      "/ai-agents",
      (location) => location.pathname.startsWith("/ai-agents"),
    ],
  },
];

applications.forEach((apps: any) => {
  singleSpa.registerApplication(apps);
});

singleSpa.getAppNames();
singleSpa.checkActivityFunctions(location);

singleSpa.triggerAppChange();

singleSpa.start({
  urlRerouteOnly: true,
});

singleSpa.addErrorHandler((_err) => {});
